@media (max-width: 1350px) {
	div.section-main-carousel {
		.main-carousel-flexbox {
			.flexbox-image {
				transform: scale(0.8);
			}
		}
	}
}

@media (max-width: @screen-md-max) {
	nav.section-header {
		.header-flexbox {
			.flexbox-logo {
				.sygnet {
					margin-right: 0px;
				}

				.logotyp {
					display: none;
				}
			}
		}
	}

	div.section-main-carousel {
		.main-carousel-flexbox {
			.flexbox-image {
				display: none;
			}

			.flexbox-description {
				width: 100%;
				min-width: 100%;
				max-width: 100%;


				img {
					margin-bottom: 0;
				}

				h1 {
					strong {
						margin-right: 0;
					}
				}

				.btn {
					margin-top: 0;
				}

				.owl-carousel {
					.item {
						text-align: center;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
					}
				}
			}
		}
	}

	div.section-shop {
		.shop-flexbox {
			.flexbox-image {
				display: none;
			}
		}
	}

	.nav-categories {
		flex-wrap: wrap;
		justify-content: center;

		.category {
			margin: 5px;
		}
	}
}

@media (max-width: @screen-sm-max) {
	nav.section-header {
		.header-flexbox {
			.search-engine-wrapper {
				margin-left: 30px;
			}
		}

		.header-bottom-wrapper {
			.header-bottom {
				.bottom-wrapper {
					border-top: none;
					position: fixed;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					overflow-y: scroll;
					background: #fff;
					z-index: 555;
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s;

					.nav-button-close {
						display: block;
					}

					.bottom-inner {
						display: flex;
						flex-direction: column;
						justify-content: center;
						min-height: 100%;
						padding: 50px;
						margin-right: 0;

						.bottom-categories {
							display: flex !important;
							flex-direction: column;

							li {
								margin-right: 0px;

								&.dropdown {
									text-align: center;

									a {
										display: block;
									}

									.dropdown-backdrop {
										display: none;
									}

									.dropdown-menu {
										margin-top: 0;
										background: none;
										padding: 0;
										border: none;
										position: static;
										float: none;
										box-shadow: none;

										li {
											a {
												text-align: center;
											}
										}
									}
								}
							}

							&:nth-child(2) {
								margin-top: 50px;
							}
						}
					}

					&.active {
						opacity: 1;
						visibility: visible;
					}
				}

				.nav-button {
					display: flex;
					margin-left: -10px;
				}
			}
		}
	}

	div.banner {
		.banner-wrapper {
			.banner-text {
				padding-left: 30px;

				h2 {
					font-size: 40px;
				}

				p {
					font-size: 20px;
				}
			}
		}
	}

	div.section-main-carousel {
		.main-carousel-flexbox {
			.flexbox-description {
				img {
					display: none;
				}

				h1 {
					font-size: 28px;

					strong {
						font-size: 40px;
					}
				}

				.owl-carousel {
					.item {
						text-align: left;
					}
				}
			}
		}
	}

	div.section-footer {
		padding: 0;
		text-align: center;

		.footer-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				margin-top: 50px;
			}
		}
	}
}

@media (max-width: @screen-xs-max) {


	div.kategoria-section-heading {
		.img-background {
			height: 120%;
			opacity: 0.5;
		}

		h1 {
			font-size: 28px;
		}

		p {
			width: 100%;
		}
	}

	div.kategoria-section-content {
		.content-flexbox {
			flex-direction: column-reverse;

			.flexbox-body {
				margin-top: 50px;
				width: 100%;
			}

			.flexbox-sidebar {
				width: 100%;

				.category-nav-button {
					display: flex;
					margin-bottom: 30px;
				}

				.category-nav-wrapper {
					position: fixed;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					overflow-y: scroll;
					background: #fff;
					z-index: 555;
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s;

					.category-nav-button-close {
						display: block;
					}

					.category-nav-wrapper-inner {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						text-align: center;
						min-height: 100%;
						padding: 50px;

						.heading {
							&:first-of-type {
								margin-top: 0 !important;
							}
						}

						.category-nav {
							li {
								a {
									justify-content: center;
								}

								&.has-child {
									.child-list {
										padding-left: 0px;
									}
								}
							}
						}

						.filter-buttons {
							align-items: center;
						}
					}

					&.active {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	div.produkt-section-content {
		.content-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-body {
				width: 100%;
				max-width: 320px;
			}

			.flexbox-sidebar {
				margin-top: 50px;
				width: 100%;

				h1 {
					font-size: 28px;
				}

				.select-wrapper-flexbox {
					margin-top: 20px;

					.select-wrapper {
						width: 100%;
						max-width: 300px;
					}
				}

				.quantity-wrapper {
					flex-direction: column;
					align-items: flex-start;

					.btn {
						margin-top: 20px;
					}
				}
			}
		}
	}

	div.section-shop {
		.shop-flexbox {
			padding: 30px;
			flex-direction: column;

			.flexbox-description {
				padding: 0;
				text-align: center;

				h2 {
					font-size: 28px;
				}
			}

			.btn {
				margin-top: 20px;
			}
		}
	}

	div.aktualnosci-section-content {
		.news-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service-wrapper {
				width: 100%;
				max-width: 330px;

				&:after {
					content: '';
					height: 1px;
					width: 100%;
					background: darken(@gray-lighter, 5%);
					display: block;
					margin-top: 30px;
				}

				&:nth-last-child(-n+2) {
					&:after {
						content: none;
					}
				}
			}
		}
	}

	div.static-page-section-content {
		.media-flexbox {
			.flexbox-service-wrapper {
				width: 48%;
			}
		}

		.category-content {
			.content-wrapper {
				flex-direction: column;
				justify-content: center;

				.wrapper-description {
					width: 100%;
					padding: 0;
				}

				.wrapper-image {
					width: 50%;
					margin-top: 40px;
				}
			}
		}
	}

	div.kontakt-section-content {
		h1 {
			font-size: 28px;
		}

		.phone {
			font-size: 22px;
		}
	}
}

@media (max-width: 570px) {

	div.banner {
		display: none;
	}

	div.section-main-carousel {
		background: @color1;
		margin-bottom: 30px;

		.main-carousel-flexbox {
			.flexbox-description {
				h1 {
					color: #fff;

					strong {
						color: #fff;
					}
				}

				.btn {
					display: none;
				}

				.owl-carousel {
					.item {
						text-align: center;
						justify-content: center;
					}
				}
			}
		}
	}

	div.produkt-section-content {
		.content-recommended {
			.products-flexbox {
				.product-service {
					width: 46%;
				}
			}
		}
	}

	div.static-page-section-content {
		.category-content {
			display: flex;
			flex-direction: column;

			.content-wrapper {
				.wrapper-description {
					width: 100%;
					padding-right: 0;
				}

				.wrapper-image {
					width: 100%;
				}
			}
		}
	}
}


@media (max-width: 480px) {
	.products-flexbox {
		.product-service {
			width: 46%;
		}
	}

	.nav-categories {
		flex-direction: column;
	}

	div.kategoria-section-content {
		.content-flexbox {
			.flexbox-body {
				.body-heading {
					flex-direction: column;
					align-items: flex-start;

					.inner-wrapper {
						flex-direction: column;
						align-items: flex-start;
					}

					.heading-grid {
						margin: 10px 0 0 0;
					}

					.select-wrapper {
						margin-top: 12px;
						margin-left: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 359px) {
	nav.section-header {
		.header-flexbox {
			flex-direction: column;

			.flexbox-logo {
				.sygnet {
					height: 45px;
					margin-right: 10px;
				}

				.logotyp {
					display: block;
					height: 15px;
				}
			}

			.search-engine-wrapper {
				width: 100%;
				margin: 15px 0 0 0;
			}
		}

		.header-bottom-wrapper {
			.header-bottom {
				.bottom-account {
					display: flex;
					align-items: center;

					li {
						margin-right: 30px;
						position: relative;

						a {
							img {
								margin-right: 0;
							}

							p {
								display: none;
							}
						}

						&:first-child {
							&:before {
								content: attr(data-quantity);
								height: 15px;
								width: 15px;
								border-radius: 50%;
								background: @color1;
								position: absolute;
								top: -10px;
								right: -10px;
								display: flex;
								align-items: center;
								justify-content: center;
								text-align: center;
								font-size: 10px;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}

	.products-flexbox {
		&.grid-list {
			.product-service {
				header {
					width: 25%;
					margin-right: 20px;
				}
			}
		}
	}
}