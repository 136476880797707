@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800&subset=latin-ext');

body {
	min-height: 100vh;
	font-family: 'Open Sans', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	.container-fluid {
		max-width: 1366px;
		margin: 0 auto;
	}

	ul,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
	}

	.btn {
		padding: 15px 35px;
		border-radius: 40px;
		text-transform: uppercase;
		font-family: "Raleway", sans-serif;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1.5px;
		white-space: normal;
		transition: all 0.3s;

		&.btn-green {
			background: @color1;
			color: #fff;
			border: 1px solid @color1;

			&:hover {
				background: #fff;
				color: #333;
				border-color: @color1;
			}
		}

		&.btn-blue {
			background: @color3;
			color: #fff;
			border: 1px solid @color3;

			&:hover {
				background: #fff;
				color: #333;
				border-color: @color3;
			}
		}

		&.btn-small {
			padding: 8px 20px;
		}
	}

	.select-wrapper {
		position: relative;

		select {
			background: #f5f5e9;
			cursor: pointer;
			padding: 10px 100px 10px 10px;
			border: none;
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			outline: none;
			display: block;
			color: #666;
			width: 100%;
			font-size: 12px;
		}

		&:after {
			cursor: pointer;
			content: '';
			height: 10px;
			width: 10px;
			background: url('../img/pictures/down-chevron.svg') center;
			background-size: cover;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.ck-editor-text {
		p {
			margin-top: 20px;
		}

		ul,
		ol {
			padding-left: 20px;
		}

		img {
			max-width: 100% !important;
		}

		*:first-child {
			margin-top: 0;
		}
	}

	.m-t-5 {
		margin-top: 5px;
	}

	.m-t-10 {
		margin-top: 10px;
	}

	.m-t-15 {
		margin-top: 15px;
	}

	.m-t-20 {
		margin-top: 20px;
	}

	.m-t-25 {
		margin-top: 25px;
	}

	.m-t-30 {
		margin-top: 30px;
	}

	.m-b-5 {
		margin-bottom: 5px;
	}

	.m-b-10 {
		margin-bottom: 10px;
	}

	.m-b-15 {
		margin-bottom: 15px;
	}

	.m-b-20 {
		margin-bottom: 20px;
	}

	.m-b-25 {
		margin-bottom: 25px;
	}

	.m-b-30 {
		margin-bottom: 30px;
	}

	.p-t-5 {
		padding-top: 5px;
	}

	.p-t-10 {
		padding-top: 10px;
	}

	.p-t-15 {
		padding-top: 15px;
	}

	.p-t-20 {
		padding-top: 20px;
	}

	.p-t-25 {
		padding-top: 25px;
	}

	.p-t-30 {
		padding-top: 30px;
	}

	.p-b-5 {
		padding-bottom: 5px;
	}

	.p-b-10 {
		padding-bottom: 10px;
	}

	.p-b-15 {
		padding-bottom: 15px;
	}

	.p-b-20 {
		padding-bottom: 20px;
	}

	.p-b-25 {
		padding-bottom: 25px;
	}

	.p-b-30 {
		padding-bottom: 30px;
	}
}

.system-alerts {
	width: 100%;
	text-align: center;

	.alert {
		border-radius: 0;
		border: none;
		height: 60px;
		display: flex;
		align-items: center;
		opacity: 0.9;
		transition: all 0.4s;

		.alert-inner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.img-hidden {
				visibility: hidden;
				opacity: 0;
				height: 18px;
			}

			p {
				color: #fff;
				padding: 0 50px;
				font-family: 15px;
			}

			button {
				color: #fff;
				opacity: 1;
				font-weight: 300;
				float: none;

				img {
					height: 18px;
				}
			}
		}

		&.alert-success {
			background: #76ad21;
		}

		&.alert-info {
			background: #218ead;
		}

		&.alert-warning {
			background: #c52b20;
		}
	}
}

.breadcrumb {
	background: none;
	margin: 0 0 20px 0;
	padding: 0;

	li {
		font-size: 11px;
		font-family: "Raleway", sans-serif;

		a {
			color: @color1;
		}
	}
}

.products-flexbox {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	overflow: hidden;

	.product-service {
		margin-top: 30px;
		padding-bottom: 50px;
		width: 28%;
		text-align: center;
		position: relative;
		transition: all 0.4s;

		.service-image-wrapper {
			width: 100%;
			padding-top: 100%;
			position: relative;

			.service-image {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				overflow: hidden;

				img {
					width: 100%;
					transition: all 0.4s;
				}
			}
		}

		.service-badges {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 20px;

			p {
				margin-right: 15px;
				font-size: 11px;
				text-transform: uppercase;
				font-weight: 700;
				font-family: "Raleway", sans-serif;

				&.promotion {
					color: @color2;
				}

				&.new {
					color: @color1;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.name {
			margin-top: 15px;
			color: #222;
			font-weight: 700;
			font-size: 16px;
			font-family: "Raleway", sans-serif;
		}

		.price {
			margin-top: 10px;
			color: #333;
			font-size: 16px;

			span {
				position: relative;
				margin-right: 10px;
				color: #999;

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					height: 1px;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					background: @color2;
				}
			}
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 300%;
			height: 1px;
			background: @gray-lighter;
		}

		&:nth-last-child(-n+4) {
			padding-bottom: 0;

			&:after {
				content: none;
			}
		}

		&:hover {
			img {
				transform: scale(0.92);
			}
		}

		&.service-hidden {
			margin: 0;
			padding: 0;
			visibility: hidden;
			opacity: 0;
		}
	}

	&.grid-list {
		.product-service {
			width: 100%;
			display: flex;
			align-items: center;
			padding-bottom: 30px;
			text-align: left;

			header {
				width: 15%;
				margin-right: 30px;
			}

			.service-badges {
				display: block;
				height: auto;
				text-align: center;

				p {
					margin: 3px 0 0 0;
				}
			}

			.name {
				margin-top: 0;
			}

			&:nth-last-child(-n+4) {
				padding-bottom: 30px;

				&:after {
					content: '';
				}
			}

			&:nth-last-child(-n+2) {
				padding-bottom: 0px;

				&:after {
					content: none;
				}
			}
		}
	}
}

.pagination-nav {
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	li {
		a {
			display: block;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: #333;
		}

		&.active {
			a {
				background: @color1;
				color: #fff;
			}
		}
	}
}

nav.section-header {
	font-family: 'Raleway', sans-serif;

	.header-flexbox {
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-logo {
			display: flex;
			align-items: center;

			.sygnet {
				height: 50px;
				margin-right: 15px;
			}

			.logotyp {
				height: 15px;
			}
		}

		.search-engine-wrapper {
			display: flex;
			align-items: center;
			position: relative;
			width: 350px;
			border-radius: 10px;
			border: 1px solid @color1;
			overflow: hidden;

			input {
				height: 40px;
				width: 100%;
				border: none;
				padding-left: 10px;
				outline: none;
			}

			button {
				border: none;
				background: @color1;
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				right: 0;
				top: 0;

				img {
					height: 55%;
				}
			}
		}

		.flexbox-navigation {
			display: flex;
			align-items: center;

			li {
				margin-right: 30px;

				a {
					display: flex;
					align-items: center;
					font-size: 12px;
					font-weight: 500;
					text-transform: uppercase;
					color: #000;
					transition: all 0.3s;

					img {
						margin-right: 10px;
						height: 16px;
					}

					.img-logo {
						width: 150px;
						height: auto;
						margin-top: -17px;
						margin-right: 0;
					}

					&:hover {
						color: @color3;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.header-bottom-wrapper {
		background: #f5f5e9;
		padding: 15px 0;

		.header-bottom {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.bottom-wrapper {
				.nav-button-close {
					position: absolute;
					display: none;
					width: 100%;
					top: 20px;
					left: 0;

					.container {
						display: flex;
						justify-content: flex-end;

						&:before,
						&:after {
							content: none;
						}
					}

					button {
						outline: none;
						border: none;
						background: none;
						padding: 15px;
						display: flex;
						justify-content: center;
						align-items: center;

						.menu-bar {
							display: block;
							position: absolute;
							width: 30px;
							height: 2px;
							background: #222;

							&:nth-child(1) {
								transform: rotate(45deg);
							}

							&:nth-child(2) {
								transform: rotate(-45deg);
							}
						}
					}
				}

				.bottom-inner {
					.bottom-categories {
						display: flex;
						align-items: center;

						li {
							margin-right: 40px;

							a {
								display: flex;
								align-items: center;
								padding: 20px 0;
								font-size: 12px;
								font-weight: 500;
								text-transform: uppercase;
								color: #000;
								transition: all 0.3s;

								img {
									margin-right: 10px;
									height: 16px;
								}

								&:hover {
									color: @color3;
								}
							}

							&:last-child {
								margin-right: 0;
							}

							&.dropdown {
								.dropdown-menu {
									padding: 0;

									li {
										margin-right: 0;

										a {
											padding: 10px;
											font-size: 11px;
										}
									}
								}
							}
						}
					}
				}
			}

			.bottom-account {
				display: flex;
				align-items: center;

				li {
					margin-right: 50px;

					a {
						display: flex;
						align-items: center;

						img {
							margin-right: 10px;
							height: 35px;
						}

						p {
							font-size: 10px;
							color: #999;
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.nav-button {
				padding: 10px;
				border: none;
				background: none;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				outline: none;
				display: none;

				.button-bar {
					height: 2px;
					width: 32px;
					background: #222;
					border-radius: 10px;
					transition: all 0.4s;
					margin-top: 4px;

					&:first-child {
						margin-top: 0;
					}
				}

				&:hover,
				&:focus {
					.button-bar {
						&:nth-child(2) {
							width: 27px;
						}
					}
				}
			}
		}
	}
}

div.banner {
	padding-bottom: 40px;

	.banner-wrapper {
		display: flex;

		// .banner-text {
		// 	width: 50%;
		// 	padding-left: 50px;
		// 	display: flex;
		// 	flex-direction: column;
		// 	justify-content: space-around;

		// 	img {
		// 		width: 200px;
		// 	}

		// 	h2 {
		// 		font-size: 70px;
		// 		color: @color3;
		// 		font-weight: 700;
		// 	}

		// 	p {
		// 		font-size: 34px;
		// 		color: #222;
		// 		font-weight: 600;
		// 	}
		// }

		.banner-image {
			width: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

div.section-main-carousel {
	padding: 50px 0;

	.main-carousel-flexbox {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-image {
			margin-left: 150px;
			position: relative;
			font-family: 'Raleway', sans-serif;

			img {
				width: 420px;
			}

			.shape {
				position: absolute;
				width: 300px;

				img {
					width: 100%;
				}

				p {
					position: absolute;
					top: -25px;
					right: 20px;
					font-size: 12px;
					font-weight: 500;
					text-transform: uppercase;
					color: #000;
				}

				&.shape-1 {
					top: 20%;
					left: 50%;
				}

				&.shape-2 {
					top: 48%;
					left: 70%;
				}

				&.shape-3 {
					top: 18%;
					left: -58%;
				}

				&.shape-4 {
					top: 57%;
					left: -45%;
				}

				&.shape-reverse {
					img {
						transform: scaleX(-1);
						filter: FlipH;
					}

					p {
						right: auto;
						left: 20px;
					}
				}
			}
		}

		.flexbox-description {
			min-width: 370px;
			max-width: 370px;
			text-align: right;

			img {
				width: 200px;
				margin-bottom: 30px;
			}

			h1 {
				font-size: 32px;
				font-family: 'Raleway', sans-serif;

				strong {
					font-size: 80px;
					color: @color3;
				}
			}

			.btn {
				margin-top: 30px;
			}

			.owl-carousel {
				.item {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
				}

				.owl-controls {
					display: none;
				}
			}
		}
	}
}

div.kategoria-section-heading {
	padding: 25px 0 50px 0;
	position: relative;

	.img-background {
		position: absolute;
		height: 150%;
		right: 0;
		top: 0;
		opacity: 0.8;
		z-index: -1;
	}

	h1 {
		font-size: 32px;
		font-family: 'Raleway', sans-serif;
	}

	p {
		margin-top: 20px;
		width: 45%;
		color: #666;
	}
}

div.kategoria-section-content {
	margin-top: 70px;
	margin-bottom: 50px;

	&.margin-index {
		margin-top: 0;
	}

	.breadcrumb {
		margin-bottom: 60px;
	}

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-body {
			width: 69%;

			.body-heading {
				margin-top: 50px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.inner-wrapper {
					display: flex;
					align-items: center;
				}

				h1,
				h2 {
					font-family: "Raleway", sans-serif;
					font-size: 16px;
					text-transform: uppercase;
					font-weight: 600;
					color: #666;
				}

				.heading-grid {
					margin-left: 20px;
					display: flex;

					a {
						margin-right: 15px;

						img {
							width: 15px;
							opacity: 0.5;
						}

						&:last-child {
							margin-right: 0;
						}

						&.active {
							img {
								opacity: 1;
							}
						}
					}
				}

				.select-wrapper {
					margin-left: 40px;
				}

				&:first-of-type {
					margin-top: 0;
				}
			}

			.empty-alert {
				margin-top: 30px;
			}

			.banner-img {
				padding-top: 60px;

				img {
					width: 100%;
				}
			}
		}

		.flexbox-sidebar {
			width: 23%;

			.heading {
				margin: 50px 0 20px 0;
				font-family: "Raleway", sans-serif;
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 600;
				color: #666;
			}

			.category-nav-button {
				width: 100%;
				align-items: center;
				justify-content: center;
				outline: none;
				display: none;

				img {
					height: 15px;
					margin-right: 20px;
				}

				&:hover {
					background: #444;
					border-color: #444;
					color: #fff;
				}
			}

			.category-nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 20px;
				left: 0;

				.container {
					display: flex;
					justify-content: flex-end;

					&:before,
					&:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;

					.menu-bar {
						display: block;
						position: absolute;
						width: 30px;
						height: 2px;
						background: #222;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.category-nav {
				li {
					margin-bottom: 15px;

					a {
						color: #666;
						transition: all 0.4s;
						display: flex;
						align-items: center;

						img {
							height: 15px;
							margin-right: 10px;
						}

						&:hover {
							color: @color1;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}

					&.active {
						a {
							color: @color1;
							font-weight: 700;
						}
					}

					&.has-child {
						.child-parent {
							color: @color1;
							font-weight: 700;
						}

						.child-list {
							margin-top: 15px;
							padding-left: 30px;
							width: 100%;

							li {
								margin-bottom: 10px;

								a {
									font-size: 12px;

									&:before {
										content: none;
									}
								}

								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}

			.filter-nav {
				li {
					margin-bottom: 15px;

					.checkbox-wrapper {
						cursor: pointer;
						margin-bottom: 0;

						.wrapper-inner {
							display: flex;
							align-items: center;
							font-weight: 400;
							color: #666;
							transition: all 0.4s;

							&:hover {
								color: @color1;
							}

							&:before {
								content: '';
								display: block;
								margin-right: 12px;
								height: 12px;
								width: 12px;
								opacity: 0.5;
								background: url('../img/pictures/success.svg') center;
								transition: all 0.4s;
							}
						}

						input {
							visibility: hidden;
							position: absolute;
						}

						&:hover {
							.wrapper-inner {
								&:before {
									opacity: 1;
								}
							}
						}
					}

					&:last-child {
						margin-bottom: 0;
					}

					.checkbox-wrapper>input:checked+.wrapper-inner {
						color: @color1;
						font-weight: 700;

						&:before {
							opacity: 1;
							background: url('../img/pictures/success-fill.svg') center;
						}
					}
				}
			}

			.filter-buttons {
				margin-top: 40px;
				display: flex;
				align-items: center;

				.btn-cancel {
					margin-left: 20px;
					text-transform: uppercase;
					font-size: 12px;
					color: #111;
					border: none;
					padding: 0;
					background: none;
				}
			}

			.description {
				p {
					color: #666;
				}

				.btn {
					margin-top: 20px;
				}
			}

			.sidebar-posts {
				.post {
					box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.35);
					margin-bottom: 20px;
					display: block;
					transition: box-shadow 0.4s;

					.service-image-wrapper {
						width: 100%;
						padding-top: 40%;
						position: relative;

						.service-image {
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 100%;
							display: flex;
							align-items: center;
							overflow: hidden;

							img {
								width: 100%;
							}
						}
					}

					.date {
						display: block;
						font-size: 12px;
						color: #999;
						padding: 0 10px;
						margin: 14px 0 10px 0;
					}

					h4 {
						padding: 0 10px 10px 10px;
						font-size: 14px;
						line-height: 27px;
						font-weight: 400;
						color: #333;
					}

					&:last-child {
						margin-bottom: 0;
					}

					&:hover {
						box-shadow: 0px 0px 1px 0px rgba(77, 151, 181, 0.9);
					}
				}
			}

			.owl-carousel {
				.owl-controls {
					display: none;
				}
			}
		}
	}
}

div.produkt-section-content {
	padding: 20px 0 50px 0;

	.breadcrumb {
		margin-bottom: 60px;
	}

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-body {
			width: 40%;

			img {
				width: 100%;
			}

			.lSAction {
				.lSPrev {
					width: 27px;
					height: 54px;
					background-image: url('../library/lightslider-master/dist/img/prev.png');
					margin-top: 0px;
					transform: translateY(-50%);
					left: 10px;
				}

				.lSNext {
					width: 27px;
					height: 54px;
					background-image: url('../library/lightslider-master/dist/img/next.png');
					margin-top: 0px;
					transform: translateY(-50%);
					right: 10px;
				}
			}

			.lSGallery {
				margin-top: 23px !important;

				li {
					border: none;
					opacity: 0.4;
					border-radius: 0;
					transition: all 0.3s;

					&.active,
					&:hover {
						opacity: 1;
					}
				}
			}
		}

		.flexbox-sidebar {
			width: 50%;

			h1 {
				font-weight: 700;
				font-family: "Raleway", sans-serif;
			}

			.manufacturer {
				margin-top: 30px;
				color: #666;
				font-size: 16px;

				a,
				span {
					color: @color1;

					&.zero {
						color: @color2;
					}
				}
			}

			.specification {
				margin-top: 10px;
				color: #666;
				font-size: 16px;

				span {
					color: @color1;
				}

				&:first-of-type {
					margin-top: 20px;
				}
			}

			.heading {
				margin-top: 30px;
				font-family: "Raleway", sans-serif;
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 600;
				color: #666;
			}

			.select-wrapper-flexbox {
				margin-top: 20px;

				label {
					color: @color1;
					text-transform: uppercase;
					font-size: 12px;
					font-weight: 400;
					width: 100px;
					padding-bottom: 5px;
				}

				.select-wrapper {
					width: 300px;
				}
			}

			.price {
				margin-top: 30px;
				font-size: 24px;
				color: @color1;

				small {
					font-size: 16px;
					color: #666;
					margin-right: 5px;
				}

				span {
					position: relative;
					color: #999;

					&:after {
						content: '';
						position: absolute;
						width: 100%;
						height: 1px;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						background: @color2;
					}
				}
			}

			.quantity-wrapper {
				margin-top: 30px;
				display: flex;
				align-items: center;

				.wrapper-inner {
					display: flex;
					margin-right: 20px;

					.input-number {
						width: 50px;
						padding: 0 12px;
						text-align: center;
						outline: none;
					}

					.input-number,
					.input-number-decrement,
					.input-number-increment {
						height: 50px;
						user-select: none;
					}

					.input-number-decrement {
						border-radius: 5px 0 0 5px;
					}

					.input-number-increment {
						border-radius: 0 5px 5px 0;
					}

					.input-number-decrement,
					.input-number-increment {
						width: 30px;
						background: @color1;
						color: #fff;
						text-align: center;
						font-weight: 700;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: background 0.4s;
						cursor: pointer;
						border: none;
						outline: none;

						&:active {
							background: #000;
						}
					}

					input[type=number]::-webkit-inner-spin-button,
					input[type=number]::-webkit-outer-spin-button {
						-moz-appearance: none;
						-webkit-appearance: none;
						appearance: none;
						margin: 0;
					}

					input[type=number] {
						-moz-appearance: textfield;
					}
				}
			}

			.ck-editor-text {
				margin-top: 20px;

				p {
					color: #666;
				}
			}

			.download-list {
				margin-top: 20px;

				li {
					a {
						display: block;
						padding: 10px 0;
						font-size: 13px;
						border-bottom: 1px solid @gray-lighter;
						color: @color3;

						&:hover {
							color: @color1;
						}
					}

					&:last-child {
						a {
							border: none;
						}
					}
				}
			}
		}
	}

	.content-recommended {
		margin-top: 50px;

		.heading {
			margin-top: 30px;
			font-family: "Raleway", sans-serif;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 600;
			color: #666;
		}

		.products-flexbox {
			.product-service {
				width: 21%;
				padding-bottom: 0;

				&:after {
					content: none;
				}
			}
		}
	}
}

div.section-shop {
	margin-bottom: 50px;

	.shop-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #f5f2e9;
		border-radius: 10px;
		overflow: hidden;
		padding-right: 50px;

		.flexbox-description {
			padding: 50px 150px 50px 50px;

			h2 {
				font-size: 32px;
				font-family: 'Raleway', sans-serif;
			}

			p {
				margin-top: 30px;
				color: #666;
			}
		}
	}
}

div.aktualnosci-section-content {
	padding: 20px 0 50px 0;

	.breadcrumb {
		margin-bottom: 20px;
	}

	.news-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.flexbox-service-wrapper {
			width: 32%;

			.flexbox-service {
				margin-top: 30px;
				width: 100%;
				padding-top: 50%;
				display: block;
				position: relative;

				.service-inner {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					display: flex;
					align-items: center;
					overflow: hidden;

					img {
						width: 100%;
						transition: all 0.3s;
					}
				}

				&:hover {
					.service-inner {
						img {
							transform: scale(1.06);
						}
					}
				}
			}

			.date {
				display: block;
				font-size: 12px;
				color: #999;
				margin: 14px 0 10px 0;
			}

			h4 {
				padding-right: 20%;
				margin-top: 10px;
				font-size: 14px;
				line-height: 27px;
				font-weight: 400;
				color: #222;
			}

			&.wrapper-hidden {
				padding: 0;
				margin: 0;
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}

div.static-page-section-content {
	padding: 20px 0 50px 0;

	.main-image {
		margin-top: 50px;
		width: 100%;
		padding-top: 40%;
		display: block;
		position: relative;
		overflow: hidden;

		.image-inner {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;

			img {
				width: 100%;
			}
		}
	}

	.heading {
		h1 {
			font-size: 32px;
			font-family: 'Raleway', sans-serif;
		}
	}

	.ck-editor-text {
		margin-top: 40px;

		h2,
		h3,
		h4 {
			color: #333;
		}

		h4 {
			line-height: 28px;
		}

		p {
			color: #666;
			line-height: 24px;

			strong {
				color: #000;
			}
		}

		ul {
			padding-left: 20px;

			li {
				margin-top: 10px;
				color: #333;
			}
		}
	}

	.scroll-to-top {
		background-color: rgba(@color1, 0.7);
		height: 45px;
		width: 45px;
		border-radius: 50%;
		position: fixed;
		z-index: 999;
		bottom: 5%;
		right: 10%;
		display: none;
		transition: all 0.3s;
		cursor: pointer;

		&:hover {
			background-color: @color1;
		}

		img {
			position: absolute;
			left: 33%;
			top: 30%;
			height: 17px;
		}
	}

	.nav-categories {
		display: flex;
		justify-content: center;
		padding: 30px 0;

		.category {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 12px;
			margin-right: 8px;
			border-radius: 10px;
			border: 1px solid @color1;
			background-color: none;
			transition: all 250ms ease-in-out;
			text-decoration: none;

			&:hover {
				background-color: @color1;

				h3 {
					color: #fff;
				}
			}

			h3 {
				font-size: 13px;
				font-weight: 600;
				text-align: center;
				line-height: 15px;
				color: #999;
			}
		}
	}

	.table-wrapper {
		display: flex;
		flex-direction: column;
		padding: 30px 0;
		overflow-x: hidden;
		width: 90%;

		.product-service {
			display: flex;
			padding: 10px;

			&:nth-of-type(odd) {
				background: #f5f5e9;
			}

			.heading {
				width: 65%;
				text-align: left;
				margin-right: 30px;
			}

			.product-content {
				width: 35%;
				display: flex;
				justify-content: flex-end;

				p {
					margin-right: 8px;
					text-align: right;
				}
			}
		}
	}

	.media-flexbox {
		margin: 40px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.flexbox-service-wrapper {
			margin-top: 15px;
			width: 23.7%;
			position: relative;
			overflow: hidden;

			.flexbox-service {
				width: 100%;
				padding-top: 66%;
				display: block;

				.service-inner {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					display: flex;
					align-items: center;

					img {
						width: 100%;
					}
				}
			}

			&.wrapper-hidden {
				margin: 0;
				padding: 0;
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	.download-list {
		margin-top: 50px;

		li {
			a {
				display: block;
				padding: 10px 0;
				font-size: 13px;
				border-bottom: 1px solid @gray-lighter;
				color: @color3;

				&:hover {
					color: @color1;
				}
			}

			&:last-child {
				a {
					border: none;
				}
			}
		}
	}

	.category-content {
		display: flex;
		padding-top: 40px;

		&.bg-color {
			background: #f5f5e9;
		}

		.content-wrapper {
			display: flex;
			align-items: center;
			padding-bottom: 60px;

			&:nth-of-type(odd) {
				flex-direction: row-reverse;
			}

			&.flex-column {
				flex-direction: column;

				h2 {
					text-align: center;
					line-height: 36px;
				}
			}

			.wrapper-description {
				width: 60%;
				padding-right: 50px;
			}

			.wrapper-image {
				width: 40%;
				margin: auto 0;

				img {
					width: 100%;
					height: 100%;
					max-height: 280px;
				}
			}
		}
	}
}

div.kontakt-section-content {
	padding: 20px 0 50px 0;

	iframe {
		margin-top: 50px;
		width: 100%;
		height: 300px;
		border: none;
	}

	h1 {
		margin-top: 50px;
		font-size: 32px;
		font-family: 'Raleway', sans-serif;
		text-align: center;
	}

	p {
		margin-top: 20px;
		color: #666;
		font-size: 16px;
		text-align: center;
	}

	.phone {
		display: block;
		font-size: 28px;
		font-weight: 700;
		color: @color1;
	}

	address {
		text-align: center;
		margin-top: 30px;
		font-size: 22px;
		font-weight: 300;
	}
}

div.section-footer {
	padding: 50px 0 0 0;
	background: #555;

	.footer-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-service {
			.section-heading-small {
				p {
					color: #999;
					text-transform: uppercase;
					font-size: 12px;
				}
			}

			.social-media {
				margin-top: 10px;

				li {
					margin-bottom: 10px;

					a {
						img {
							width: 90px;
							opacity: 0.9;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.contact {
				margin-top: 10px;

				li {
					margin-bottom: 7px;

					a {
						display: flex;
						align-items: center;
						font-size: 16px;
						color: #fff;
						font-weight: 500;
						font-family: "Raleway", sans-serif;
						transition: all 0.4s;

						img {
							margin-right: 10px;
							height: 15px;
						}

						&:hover {
							color: @color1;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.description {
				margin-top: 10px;

				p {
					color: #fff;
					font-family: "Raleway", sans-serif;
				}
			}

			.categories {
				margin-top: 10px;

				li {
					margin-bottom: 7px;

					a {
						color: #fff;
						font-family: "Raleway", sans-serif;
						transition: all 0.4s;

						&:hover {
							color: @color1;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.alert {
		background: none;
		border-radius: 0;
		border: none;
		border-top: 1px solid #666;
		padding: 30px 0 0 0;
		margin-top: 30px;
		margin-bottom: 0;

		p {
			font-size: 12px;
			color: #999;
			font-weight: 300;

			a {
				color: @color1;
				cursor: pointer;
			}
		}
	}

	.footer-credits {
		margin-top: 30px;
		padding: 30px 0 30px 0;
		border-top: 1px solid #666;

		p {
			font-size: 12px;
			color: #999;
			font-weight: 300;

			a {
				color: @color1;
			}
		}
	}
}